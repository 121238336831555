<template>
  <div class="main-contain_con">
    <div class="contain_up">
      <div class="title_con">
        <div class="title_text_border" :style="{ height: !courseType ? '160px' : '80px' }">
          <div class="left_circle"></div>
          <div class="right_circle"></div>
          <!-- <el-button class="bt" @click="tolearning" :loading="btloading">加入学习</el-button> -->
          <el-button
            class="bt"
            @click="tolearning"
            v-if="courseSemesterInfo.is_join == 0 && !courseType"
          >
            <div>加入学习</div>
            <!-- <div v-if="courseSemesterInfo.is_join == 1">进入学习</div> -->
          </el-button>
          <div
            :class="!courseType ? 'title_text1' : 'title_text'"
          >{{ courseSemesterInfo.name || "" }}</div>
          <div class="time_text" v-if="!courseType">
            开课时间: {{ courseSemesterInfo.start_time | dataformat }} ~
            {{ courseSemesterInfo.end_time | dataformat }}
          </div>
        </div>
      </div>
    </div>
    <div class="contain_down">
      <div class="left_cont">
        <div class="title_con">
          <div class="title">
            <div class="title-left">
              <template v-if="!courseType">
                <el-image
                  :class="courseSemesterInfo.cover ? 'elimage' : 'elimage1'"
                  :src="
                    courseSemesterInfo.cover ? courseSemesterInfo.cover : image1
                  "
                  @error="handleError"
                />
                <el-image class="statusimg" :src="statusImg" style="max-height: 330px" />
              </template>
              <template v-if="courseType && isMobile == 1">
                <video
                  id="myVideo"
                  width="100%"
                  height="450"
                  :poster="courseSemesterInfo.home_cover"
                  controls
                  controlslist="nodownload noplaybackrate"
                  disablepictureinpicture
                  autoplay
                  preload="auto"
                  :src="courseSemesterInfo.cover"
                  ref="myVideo"
                />
              </template>
              <template v-else-if="courseType && isMobile == 0">
                <video
                  id="myVideo"
                  width="100%"
                  height="250"
                  :poster="courseSemesterInfo.home_cover"
                  controls
                  controlslist="nodownload noplaybackrate"
                  disablepictureinpicture
                  autoplay
                  preload="auto"
                  :src="courseSemesterInfo.cover"
                  ref="myVideo"
                />
              </template>
            </div>
            <div class="title-right">
              <div class="intro-title">
                <div class="item" v-if="!courseType">
                  <!-- <el-image class="elimage" :src="icon2" /> -->
                  <div class="item-name">所属学科：</div>
                  <div class="item-text">{{ courseSemesterInfo.subject_config_info?.name || "" }}</div>
                </div>
                <div class="item" v-if="!courseType">
                  <!-- <el-image class="elimage" :src="icon13" /> -->
                  <div class="item-name">课程性质：</div>
                  <div
                    class="item-text"
                  >{{ courseSemesterInfo.course_type_config_info?.name || "" }}</div>
                </div>
                <div class="item">
                  <!-- <el-image class="elimage" :src="icon12" /> -->
                  <div class="item-name">课程类别：</div>
                  <div class="item-text">
                    {{
                    courseSemesterInfo.course_classify_config_info?.name || ""
                    }}
                  </div>
                </div>
                <div class="item">
                  <!-- <el-image class="elimage" :src="icon1" /> -->
                  <div class="item-name">主讲老师：</div>
                  <div class="item-text">{{ courseSemesterInfo.leading_user_info?.toString() }}</div>
                </div>
                <div class="item map-wrap" v-if="courseType && isMobile == 1">
                  <div class="item-info">
                    <div class="item-name">上课地点：</div>
                    <div class="item-text">
                      {{ courseSemesterInfo.detail_address || "" }}
                      <span
                        style="color: red; font-size: 20px"
                        class="el-icon-location-outline"
                        v-if="courseSemesterInfo?.detail_address"
                      ></span>
                    </div>
                  </div>
                  <div class="map-cont">
                    <div class="map-display">
                      <Map :center-point="centerPoint"></Map>
                    </div>
                  </div>
                </div>
                <div class="item1 map-wrap" v-if="courseType && isMobile == 0">
                  <div class="item-info">
                    <div class="item-name">上课地点：</div>
                    <div class="item-text">
                      {{ courseSemesterInfo.detail_address || "" }}
                      <span
                        v-if="courseSemesterInfo?.detail_address"
                        class="el-icon-location-outline"
                        style="color: red; font-size: 20px"
                      />
                    </div>
                  </div>
                  <div class="map-cont">
                    <div class="map-display">
                      <Map :center-point="centerPoint"></Map>
                    </div>
                  </div>
                </div>
              </div>
              <div class="semester">
                <div class="title">
                  开课
                  <span class="num">{{ courseInfo.nper }}</span> 学期
                </div>
                <el-select
                  v-model="courseSemesterSn"
                  class="select"
                  placeholder="请选择"
                  @change="switcCourse"
                >
                  <el-option
                    v-for="item in courseSemesters"
                    :key="item.id"
                    class="select_opt"
                    :label="item.name"
                    :value="item.sn"
                  >
                    <span class="opt_name">{{ item.name }}</span>
                    <span class="opt_status">
                      {{
                      item.status | statusFilter
                      }}
                    </span>
                  </el-option>
                </el-select>
              </div>

              <div
                class="bottom-title"
                :style="{
                  justifyContent: courseType ? 'flex-start' : 'space-between',
                }"
              >
                <div class="item" v-if="!courseType">
                  <div class="item-bottom">
                    <div class="num">{{ courseSemesterInfo.credit || 0 }}</div>
                    <div class="num-title">学分</div>
                  </div>
                </div>

                <div class="item" v-if="!courseType">
                  <div class="item-bottom">
                    <div class="num num2">{{ courseSemesterInfo.class_hours || 0 }}</div>
                    <div class="num-title">学时</div>
                  </div>
                </div>

                <div class="item">
                  <div class="item-bottom">
                    <div class="num num3">{{ courseSemesterInfo.join_count || 0 }}</div>
                    <div class="num-title">加入人数</div>
                  </div>
                </div>

                <div class="item">
                  <div class="item-bottom">
                    <div class="num num4">{{ collect_count }}</div>
                    <div class="num-title">累计收藏数</div>
                  </div>
                </div>
              </div>
              <div class="title-right-head">
                <div class="right-bt">
                  <div class="bt2" @click="collectbt()">
                    <template v-if="!is_collect">
                      <el-image class="elimage" :src="collecticon1" />
                      <div class="text">收藏</div>
                    </template>
                    <template v-else>
                      <el-image class="elimage" :src="collecticon2" />
                      <div class="text">已收藏</div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="bottom_con">
            <div class="tabbt-contain">
              <div
                :class="activebt == 'bt5' ? 'bt btactive' : 'bt'"
                @click="btclick('bt5', 'Courseregistration')"
                v-if="courseType"
              >
                <div
                  :class="
                    activebt == 'bt5' ? 'bt-name bt-name-active' : 'bt-name'
                  "
                >课程报名</div>
              </div>
              <div
                :class="activebt == 'bt1' ? 'bt btactive' : 'bt'"
                @click.stop="btclick('bt1', 'Courseintroduction')"
              >
                <div
                  :class="
                    activebt == 'bt1' ? 'bt-name bt-name-active' : 'bt-name'
                  "
                >课程介绍</div>
              </div>
              <div
                :class="activebt == 'bt2' ? 'bt btactive' : 'bt'"
                @click="btclick('bt2', 'Teachteam')"
              >
                <div
                  :class="
                    activebt == 'bt2' ? 'bt-name bt-name-active' : 'bt-name'
                  "
                >教学团队</div>
              </div>
              <div
                :class="activebt == 'bt3' ? 'bt btactive' : 'bt'"
                @click="btclick('bt3', 'Courseoutline')"
                v-if="!courseType"
              >
                <div
                  :class="
                    activebt == 'bt3' ? 'bt-name bt-name-active' : 'bt-name'
                  "
                >课程大纲</div>
              </div>
              <div
                :class="activebt == 'bt4' ? 'bt btactive' : 'bt'"
                @click="btclick('bt4', 'Virtualsimulationexperiment')"
                v-if="!courseType"
              >
                <div
                  :class="
                    activebt == 'bt4' ? 'bt-name bt-name-active' : 'bt-name'
                  "
                >虚拟仿真实验</div>
              </div>
            </div>
            <component :is="componentId" ref="mycomponent"></component>
          </div>
        </div>
      </div>

      <div class="right_cont" v-if="resCourseList.length > 0">
        <div class="title">相关课程</div>
        <div class="rec_course">
          <div
            v-for="(item, inx) in resCourseList"
            :key="inx"
            class="course_block"
            @click="to_detail(item)"
          >
            <div class="course_block_img">
              <el-image :src="item.cover" />
            </div>
            <el-tooltip :content="item.name" placement="top" effect="light" v-if="item.flag">
              <div class="course_block_text">{{ item.name }}</div>
            </el-tooltip>
            <div class="course_block_text" v-else>{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* 页面 - 组件 */
import Courseintroduction from "./components/courseintroduction.vue"
import Teachteam from "./components/teachteam.vue"
import Courseoutline from "./components/courseoutlines.vue"
import Virtualsimulationexperiment from "./components/virtualsimulationexperiments.vue"
import Courseregistration from "./components/courseregistration"
import Videoshow from "./components/videoshow.vue"
/* 方法 - 接口 */
import {
  getcourseinfo,
  joincourse,
  getCourseSemesterList,
  getCourseSemesterInfo,
  getRecCourseSemesterList,
} from "@/api/course"
import { setuserfavorite } from "@/api/userfavorite"
import Map from "@/components/TencentMap/index.vue"
import dayjs from "dayjs"
import { getToken } from "@/utils/auth"
const statusMap = {
  0: "未开始",
  1: "进行中",
  2: "已结束",
}

export default {
  name: "mainpage",
  components: {
    Courseintroduction,
    Teachteam,
    Courseoutline,
    Virtualsimulationexperiment,
    Courseregistration,
    Videoshow,
    Map,
  },
  inject: ["reload"],
  data () {
    return {
      token: getToken() ? true : false,
      // btloading: false,
      // tolearnstate: 0,
      collectstate: 0,
      courseSn: "", // 课程编号
      courseInfo: {}, // 课程信息
      courseKey: "", // 加入课程的钥匙
      courseSemesterId: "", // 课程课期ID
      courseSemesterSn: "", // 课程课期ID
      courseSemesterInfo: {}, // 课程课期信息
      courseSemesters: [], // 课程课期列表
      componentId: "",
      activebt: "",
      total: 0,
      searchvalue: "",
      image1: require("@/assets/defaultImage.png"),
      statusImg: "",
      is_join: "", //0 加入学习 1进入学习
      statusImg1: require("@/assets/coursedetails/st_not_start.png"),
      statusImg2: require("@/assets/coursedetails/st_ing.png"),
      statusImg3: require("@/assets/coursedetails/st_over.png"),

      icon1: require("@/assets/coursedetails/图层 57.png"),
      icon2: require("@/assets/coursedetails/学科.png"),
      icon3: require("@/assets/coursedetails/学分_线性.png"),
      icon4: require("@/assets/coursedetails/时间.png"),
      icon5: require("@/assets/coursedetails/人.png"),
      icon6: require("@/assets/coursedetails/收藏 拷贝.png"),
      icon12: require("@/assets/coursedetails/leibie.png"),
      icon13: require("@/assets/coursedetails/kcxz.png"),

      icon7: require("@/assets/coursedetails/图层 61.png"),
      icon77: require("@/assets/coursedetails/new/图层 61.png"),
      icon8: require("@/assets/coursedetails/图层 62.png"),
      icon88: require("@/assets/coursedetails/new/图层 62.png"),
      icon9: require("@/assets/coursedetails/课程管理 (1).png"),
      icon99: require("@/assets/coursedetails/new/课程管理.png"),
      icon10: require("@/assets/coursedetails/实验 烧杯 2.png"),
      icon1010: require("@/assets/coursedetails/new/实验 烧杯 2.png"),
      icon11: require("@/assets/coursedetails/视频.png"),
      icon1111: require("@/assets/coursedetails/new/视频.png"),
      collecticon1: require("@/assets/newchange/collect_11.png"),
      collecticon2: require("@/assets/newchange/collect_22.png"),
      testimage: require("@/assets/test.png"),
      testimageicon: require("@/assets/矩形图标.png"),

      image01: require("@/assets/defaultimages/1首页--1实验用房使用排行_23.jpg"),
      image02: require("@/assets/defaultimages/1首页--1实验用房使用排行_25.jpg"),
      image03: require("@/assets/defaultimages/1首页--1实验用房使用排行_36.jpg"),
      image04: require("@/assets/defaultimages/1首页--1实验用房使用排行_60.jpg"),
      image05: require("@/assets/defaultimages/1首页--1实验用房使用排行_57.jpg"),
      image06: require("@/assets/defaultimages/1首页--1实验用房使用排行_41.jpg"),
      image07: require("@/assets/defaultimages/1首页--1实验用房使用排行_44.jpg"),
      image08: require("@/assets/defaultimages/1首页--1实验用房使用排行_03.jpg"),
      resCourseList: [],
      courseType: false,
      centerPoint: {}, // 中心坐标点
      isMobile: "", // 判断手机端字段
      is_collect: 0, // 判断是否收藏
      collect_count: null,
    }
  },
  computed: {},
  filters: {
    dataformat (value) {
      if (!value) return ""
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm")
    },
    statusFilter (status) {
      return statusMap[status]
    },
  },
  watch: {
    resCourseList: {
      handler (newVal) {
        if (!this._isMobile()) {
          let resDis = document.querySelector(".left_cont")
          let resDown = document.querySelector(".contain_down")
          if (newVal.length > 0) {
            resDis.style["width"] = "calc(100% - 390px)"
            let dis = document.querySelector(".right_cont")
            resDown.style["width"] = "1640px"
            if (dis) {
              dis.style["width"] = "390px"
            }
          } else {
            if (resDis) {
              resDis.style["width"] = "100%"
              resDown.style["width"] = "1200px"
            }
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created () {
    this.courseType = this.$route.query.courseType ? true : false
    this.courseSn = decodeURIComponent(this.$route.query.course_sn)
    // 原来的逻辑
    // if (getToken()) {
    //   this.initdata();
    // } else {
    //   this.$router.push({
    //     path: "/home/wisdompracticeteaching/coursedetails",
    //     query: {
    //       course_sn: this.courseSn,
    //     },
    //   });
    // }
    // wangwenhao 更改的逻辑
    this.initdata()
    if (!getToken()) {
      this.$router.push({
        path: "/home/wisdompracticeteaching/coursedetails",
        query: {
          course_sn: this.courseSn,
        },
      })
    }
    // 判断设备类型
    if (this._isMobile()) {
      this.isMobile = 0
      if (this.$route.query?.courseType == 1) {
        this.activebt = "bt5"
        this.componentId = "Courseregistration"
      } else {
        this.activebt = "bt1"
        this.componentId = "Courseintroduction"
      }
    } else {
      this.isMobile = 1
      if (this.$route.query?.courseType == 1) {
        this.activebt = "bt5"
        this.componentId = "Courseregistration"
      } else {
        this.activebt = "bt1"
        this.componentId = "Courseintroduction"
      }
    }
  },
  methods: {
    handleError (e) {
      // 当图片加载失败时，将图片地址设置为默认图片
      e.target.src = this.image1
      // 可以添加额外的处理，比如提示用户或者进行日志记录
    },
    // 初始化页面
    initdata () {
      if (this.$route.query.course_semester_sn) {
        this.courseSemesterSn = decodeURIComponent(
          this.$route.query.course_semester_sn
        )
        this.setCourseSemester()
      } else {
        this.getcourseinfo()
      }
    },
    // 获取课程详细信息
    getcourseinfo () {
      const params = { course_sn: this.courseSn }
      getcourseinfo(params)
        .then((res) => {
          if (res.code === 0 && res.data) {
            // console.log("课程详细信息", res.data);
            this.courseInfo = res.data.course_info // 自定义对象接收课程详情
            this.courseSemesterId = this.courseInfo.newest_course_semester_id
            this.courseSemesterInfo = res.data.info // 自定义对象接收课程课期详情
            this.courseSemesterSn = this.courseSemesterInfo.course_semester_sn
            this.courseKey = this.courseSemesterSn
            // 判断当前课期状态
            if (res.data.info?.course_status == 0) {
              this.statusImg = this.statusImg1
            } else if (res.data.info?.course_status == 1) {
              this.statusImg = this.statusImg2
            } else {
              this.statusImg = this.statusImg3
            }
            this.is_collect = res.data.info?.is_collect // 是否收藏
            this.collect_count = res.data.info?.collect_count || 0 // 收藏数
            // 课程地址定位中心点
            this.centerPoint = {
              address: res.data.info?.address?.address,
              lng: res.data.info?.address?.longitude,
              lat: res.data.info?.address?.latitude,
            }
            this.$refs.mycomponent.initdata(
              this.courseSemesterInfo,
              this.courseKey
            )
            this.getCourseSemesterList() // 获取课程的课期
            this.getRecCourseList() // 获取相关课程
          } else if (res.code === 3) {
            // this.$message.warning('该课程不存在或已下架!')
            this.$router.push({
              path: "/home/wisdompracticeteaching/mainpage",
            })
          }
        })
        .catch(() => { })
    },
    // 获取课程课期列表
    getCourseSemesterList () {
      const params = {
        course_sn: this.courseInfo.course_sn,
        page: 1,
        per_page: 100,
      }
      getCourseSemesterList(params).then((res) => {
        if (res.code === 0 && res.data) {
          this.courseSemesters = res.data.map((item) => {
            return {
              id: item.course_semester_id,
              sn: item.course_semester_sn,
              name:
                item.start_year +
                "-" +
                item.end_year +
                " 第" +
                item.semester_nper +
                "学期",
              status: item.time_status,
            }
          }) // 课期列表
        }
      })
    },
    // 切换课期
    switcCourse (e) {
      this.courseSemesterSn = e
      this.setCourseSemester()
    },
    // 设置课期
    setCourseSemester () {
      const params = { course_semester_sn: this.courseSemesterSn }
      // 获取课期详情
      getCourseSemesterInfo(params).then((res) => {
        if (res.code === 0 && res.data) {
          // 判断新课期是否已加入
          if (Number(res.data.info.is_join) === 1) {
            this.$router.push({
              path: "/home/wisdompracticeteaching/courselearning",
              query: {
                course_sn: this.courseSn,
                course_semester_sn: this.courseSemesterSn,
              },
            })
          } else {
            this.courseInfo = res.data.course_info // 自定义对象接收课程详情
            this.courseSemesterId = this.courseInfo.newest_course_semester_id
            this.courseSemesterInfo = res.data.info // 自定义对象接收课程课期详情
            this.courseSemesterSn = this.courseSemesterInfo.course_semester_sn
            this.courseKey = this.courseSemesterSn
            // 判断当前课期状态
            if (res.data.info?.course_status == 0) {
              this.statusImg = this.statusImg1
            } else if (res.data.info?.course_status == 1) {
              this.statusImg = this.statusImg2
            } else {
              this.statusImg = this.statusImg3
            }
            this.is_collect = res.data.info?.is_collect // 是否收藏
            this.collect_count = res.data.info?.collect_count || 0 // 收藏数
            // 课程地址定位中心点
            this.centerPoint = {
              address: res.data.info?.address?.address,
              lng: res.data.info?.address?.longitude,
              lat: res.data.info?.address?.latitude,
            }
            this.$refs.mycomponent.initdata(
              this.courseSemesterInfo,
              this.courseKey
            )
            this.getCourseSemesterList() // 获取课程的课期
            this.getRecCourseList() // 获取相关课程
          }
        }
      })
    },
    // 获取课程标签相关的课程列表
    getRecCourseList () {
      this.resCourseList = []
      const params = {
        course_semester_sn: this.courseSemesterSn,
        page: 1,
        per_page: 10000,
      }
      getRecCourseSemesterList(params).then((res) => {
        if (res.code === 0) {
          res.data.data.map((item) => {
            if (item.name.length > 14) {
              item.flag = true
            }
          })
          this.resCourseList = res.data.data
        }
      })
    },
    // 判断设备类型
    _isMobile () {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    },

    to_detail (item) {
      // debugger
      if (item.is_join == 1) {
        this.$router.push({
          path: "/home/wisdompracticeteaching/courselearning",
          query: {
            course_semester_sn: item.course_semester_sn,
          },
        })
      } else {
        this.$router.push({
          path: "/home/wisdompracticeteaching/coursedetails",
          query: {
            course_semester_sn: item.course_semester_sn,
          },
        })

        this.reload()
      }
    },
    randomimage () {
      let items = [
        this.image01,
        this.image02,
        this.image03,
        this.image04,
        this.image05,
        this.image06,
        this.image07,
        this.image08,
      ]
      let item = items[Math.floor(Math.random() * items.length)]
      return item
    },
    // 点击收藏
    collectbt () {
      if (this.token) {
        this.setuserfavorite()
      } else {
        this.$confirm("请先登录!", "登录提示", {
          confirmButtonText: "去登录",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "/login",
            })
          })
          .catch(() => { })
      }
    },
    // 收藏数据
    setuserfavorite () {
      const params = {
        course_sn: this.courseSn,
        // course_semester_sn: this.courseKey,
        type: 1,
      }
      setuserfavorite(params)
        .then((res) => {
          //console.log(res, "res")
          if (res.code === 0) {
            this.is_collect = !this.is_collect
            this.collect_count = this.is_collect
              ? this.collect_count + 1
              : this.collect_count - 1
            // this.getcourseinfo({course_semester_sn: this.courseKey,})
            // if (is_collect == 0) {
            //   localStorage.setItem('is_collect', 1)
            //   this.$message({
            //     message: '恭喜您，收藏课程成功',
            //     type: 'success'
            //   })
            //   console.log('this.is_collect', this.is_collect)
            // } else {
            //   localStorage.setItem('is_collect', 0)
            //   this.$message({
            //     message: '取消收藏成功'
            //   })
            // }
          }
        })
        .catch(() => { })
    },
    btclick (name, component) {
      this.activebt = name
      this.componentId = component
      this.$nextTick(() => {
        this.$refs.mycomponent.initdata(
          this.courseSemesterInfo,
          this.courseKey
        )
      })
    },
    tolearning () {
      if (this.token) {
        // if (this.courseSemesterInfo.is_join == 0) {
        //   this.btloading = true;
        //   this.joincourse({
        //     course_semester_sn: this.courseKey,
        //   });
        // } else if (this.courseSemesterInfo.is_join == 1) {
        //   // this.tolearnstate = 0;
        //   this.$router.push({
        //     path: "/home/wisdompracticeteaching/courselearning",
        //     query: {
        //       course_sn: this.courseInfo.course_sn,
        //     },
        //   });
        // }
        let params = {
          course_semester_sn: this.courseKey,
        }
        joincourse(params)
          .then((response) => {
            // this.btloading = false;
            if (response.code == 0) {
              this.$message({
                message: "恭喜您，加入课程成功!",
                type: "success",
              })
              this.$router.push({
                path: "/home/wisdompracticeteaching/courselearning",
                query: {
                  course_sn: this.courseSn,
                  course_semester_sn: this.courseSemesterSn,
                },
              })
              // this.tolearnstate = 1;
            }
          })
          .catch(() => { })
      } else {
        this.$confirm("请先登录!", "登录提示", {
          confirmButtonText: "去登录",
          cancelButtonText: "取消",
          type: "warning",
          customClass: "myClass",
        })
          .then(() => {
            this.$router.push({
              path: "/login",
            })
            localStorage.setItem("login_path", "/home/wisdompracticeteaching/courselearning?course_sn="+this.courseSn + "&course_semester_sn="+this.courseSemesterSn)
          })
          .catch(() => { })
      }
    },
    // joincourse(params) {
    //   joincourse(params)
    //     .then((response) => {
    //       this.btloading = false;
    //       if (response.code == 0) {
    //         // this.tolearnstate = 1;
    //       }
    //     })
    //     .catch((error) => {
    //       //console.log(error);
    //     });
    // },
    // showActive () {
    //   let dis = document.querySelector('.map-display')
    //   dis.setAttribute('class', 'map-display is_map_show')
    // },
    // hideActive () {
    //   let dis = document.querySelector('.map-display')
    //   dis.setAttribute('class', 'map-display is_map_hide')
    // },
  },
};
</script>
<style scoped lang="scss">
.main-contain_con {
  overflow: hidden;

  .contain_up {
    background: url(~@/assets/newchange/bg_4444.jpg) no-repeat;
    background-size: 100% 100%;

    .title_con {
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;

      .title_text_border {
        min-width: 325px;
        position: relative;
        border-top: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;

        .left_circle {
          width: 16px;
          height: 16px;
          border: 1px solid #ffffff;
          border-radius: 50%;
          transform: translate(-100%, -50%);
          position: absolute;
          top: 0px;
          left: 0px;
        }

        .right_circle {
          width: 16px;
          height: 16px;
          border: 1px solid #ffffff;
          border-radius: 50%;
          transform: translate(100%, 50%);
          position: absolute;
          bottom: 0px;
          right: 0px;
        }

        .bt {
          color: #ffffff;
          font-size: 14px;
          font-weight: 400;
          width: 151px;
          height: 40px;
          background: #3d84ff;
          border-radius: 20px;
          border-color: #3d84ff;
          opacity: none;
          transform: translate(-50%, 50%);
          position: absolute;
          bottom: 0px;
          left: 50%;
        }

        .time_text {
          margin-top: 10px;
          font-size: 14px;
        }
      }
    }
  }

  .contain_down {
    .title_con {
      border-radius: 4px;

      .title {
        height: 100%;
        width: 100%;

        .title-left {
          position: relative;
          .statusimg {
            width: 80px;
            height: 80px;
            position: absolute;
            left: -2px;
            top: -2px;
          }
        }

        .title-right {
          .intro-title {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            .item {
              width: 50%;
              margin-top: 16px;
              // margin-right: 35px;
              // align-items: center;

              .elimage {
                width: 23px;
                // height: 23px;
              }

              .item-name {
                // margin-left: 10px;
                // margin-right: 10px;
                font-size: 14px;
                font-weight: 500;
                color: #999999;
                white-space: nowrap;
              }

              .item-text {
                font-size: 14px;
                font-weight: 500;
                // color: #333333;
                white-space: nowrap;
              }
            }

            .item:nth-child(1),
            .item:nth-child(2) {
              margin-top: 0px;
            }
          }

          // .title-footer {
          //   display: flex;
          //   justify-content: center !important;
          //   align-items: center;
          //   margin-left: -20px;

          //   .item {
          //     // margin-left: 30px;
          //     // min-width: 70px;
          //   }
          // }

          .bottom-title {
            margin-left: 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;

            .text {
              color: #333333;
              font-size: 14px;
              font-weight: 400;
              line-height: 25px;
              word-break: break-word;
              /* 英文-字母 */
              word-wrap: break-word;
              /* 英文-单词 */
              white-space: pre-line;
              /* 中文 */
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              line-clamp: 4;
              -webkit-box-orient: vertical;
            }

            .score {
              margin-left: 10px;
              margin-right: 10px;
              font-size: 14px;
              font-weight: 400;
              color: #666666;
            }

            .num {
              font-size: 30px;
              font-weight: 500;
              color: #3d84ff;
            }

            .numname {
              font-size: 20px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #666666;
            }
          }

          .title-right-head {
            margin-top: 30px;
            // display: flex;
            // justify-content: flex-end;
            // align-items: center;
            float: right;
            margin-right: 5%;
            .bt2 {
              text-align: center;
            }
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: center;
    align-items: center;

    .bottom_con {
      min-height: 400px;

      .tabbt-contain {
        border-bottom: 1px solid #cccccc;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .bt {
          // margin-right: 90px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-bottom: 6px;
          border-bottom: 2px solid transparent;
          user-select: none;
          cursor: pointer;

          .bt-icon {
            margin-right: 6px;
          }

          .bt-name {
            font-family: PingFang SC;
            font-weight: 400;
            color: #222222;
          }

          .bt-name-active {
            color: #3d84ff;
          }
        }

        .btactive {
          border-bottom: 2px solid #3d84ff;
        }
      }
    }
  }

  .map-wrap {
    display: inline-block;

    ::v-deep .map_seo {
      display: none;
    }

    .map-cont {
      margin-top: 16px;
      width: 370px;
      height: 200px;
      position: relative;

      .map-display {
        position: absolute;
        width: 100%;
        height: 100%;
        // height: 0px;
        overflow: hidden;
      }

      // .is_map_show {
      //   animation: showMap 0.8s forwards;
      // }
      // .is_map_hide {
      //   animation: hideMap 0.8s forwards;
      // }
    }
  }
}

@media only screen and (max-width: 640px) {
  .main-contain_con {
    margin-top: 66px;

    .contain_up {
      // display: flex;
      // justify-content: center;
      // align-items: center;
      height: 100%;

      .title_con {
        padding: 25px;

        .title_text_border {
          height: 160px;

          .title_text1 {
            font-size: 30px;
            color: #ffffff;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            margin-top: 40px;
          }

          .title_text {
            font-size: 30px;
            color: #ffffff;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            margin-top: 30px;
          }

          .time_text {
            color: #ffffff;
          }
        }
      }
    }

    .contain_down {
      width: 100%;
      // margin: 0px auto;
      // display: flex;
      // justify-content: center;
      display: block;
      .left_cont {
        // width: calc(100% - 390px);
      }
      .right_cont {
        // width: 300px;
        padding-left: 10px;
        box-sizing: border-box;

        .title {
          font-size: 14px;
          font-weight: 600;
          color: #333333;
          padding: 20px 10px 10px;
        }

        .rec_course {
          // width: 240px;
          // height: 1055px;
          // overflow-y: scroll;

          .course_block {
            // padding-top: 20px;
            cursor: pointer;
            float: left;
            width: calc((100% - 20px) / 2);
            margin-left: 5px;
            margin-right: 5px;

            .course_block_img {
              width: 100%;
              height: 146px;
              border-radius: 5px;
              overflow: hidden;

              ::v-deep .el-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .course_block_text {
              width: calc(100% - 20px);
              text-align: center;
              padding: 10px 10px 0px;
              font-size: 14px;
              line-height: 18px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #666666;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .course_block:first-child {
            // padding-top: 10px;
          }

          .course_block:last-child {
            padding-bottom: 20px;
          }
        }

        ::v-deep .rec_course::-webkit-scrollbar {
          /*滚动条的宽度*/
          width: 0px;
        }
      }

      .title_con {
        // margin-top: 25px;

        .title {
          // display: flex;
          // justify-content: space-between;
          // align-items: flex-start;

          .title-left {
            width: 100%;
            .elimage {
              width: 100%;
              height: 100%;
              border-radius: 5px;
              background: aliceblue;
            }
            .elimage1 {
              width: 100%;
              height: 100%;
              border-radius: 5px;
              background: aliceblue;
              ::v-deep .el-image__inner {
                position: relative;
                width: 65%;
                left: 15%;
              }
            }
          }

          .title-right {
            width: 100%;
            min-height: 155px;
            margin-left: 10px;
            margin-top: 25px;

            .intro-title {
              align-items: center;

              .item {
                // display: flex;
                // justify-content: flex-start;

                .elimage {
                  width: 23px;
                  // height: 23px;
                }

                .item-text {
                  margin-top: 5px;
                }
              }

              .item1 {
                width: 100%;
                margin-top: 16px;
                // margin-right: 35px;
                // display: flex;
                // justify-content: flex-start;
                // align-items: center;

                .elimage {
                  width: 23px;
                  // height: 23px;
                }

                .item-name {
                  // margin-left: 10px;
                  // margin-right: 10px;
                  font-size: 14px;
                  font-weight: 500;
                  color: #999999;
                  // white-space: nowrap;
                }

                .item-text {
                  font-size: 14px;
                  font-weight: 500;
                  // color: #333333;
                  // white-space: nowrap;
                  margin-top: 5px;
                }
              }
            }

            // .title-footer {
            //   margin-top: 20px;
            //   justify-content: center !important;
            //   margin-left: -20px;

            //   .item {
            //     width: 30%;
            //   }
            // }
            .bottom-title {
              margin-left: 0px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 20px;

              .item {
                width: 30%;
                text-align: center;
              }

              .text {
                color: #333333;
                font-size: 14px;
                font-weight: 400;
                line-height: 25px;
                word-break: break-word;
                /* 英文-字母 */
                word-wrap: break-word;
                /* 英文-单词 */
                white-space: pre-line;
                /* 中文 */
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                line-clamp: 4;
                -webkit-box-orient: vertical;
              }

              .score {
                margin-left: 10px;
                margin-right: 10px;
                font-size: 14px;
                font-weight: 400;
                color: #666666;
              }

              .num {
                font-size: 30px;
                font-weight: 500;
                color: #3d84ff;
              }

              .numname {
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #666666;
              }
            }

            .title-right-head {
              margin-top: 30px;
              // display: flex;
              // justify-content: flex-end;
              // align-items: center;
              float: right;
              margin-right: 5%;
            }
          }
        }
      }

      .bottom {
        margin-top: 90px;

        .bottom_con {
          width: 100%;

          .tabbt-contain {
            .bt {
              padding-left: 10px;
              padding-right: 10px;

              .bt-name {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 640px) and (max-width: 1200px) {
  .main-contain_con {
    margin-top: 100px;

    .contain_up {
      // display: flex;
      // justify-content: center;
      // align-items: center;
      height: 100%;

      .title_con {
        padding: 25px;

        .title_text_border {
          height: 160px;

          .title_text1 {
            font-size: 30px;
            color: #ffffff;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            margin-top: 40px;
          }

          .title_text {
            font-size: 30px;
            color: #ffffff;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            margin-top: 30px;
          }
        }
      }
    }

    .contain_down {
      width: 100%;
      // margin: 0px auto;
      // display: flex;
      // justify-content: center;
      display: block;
      .left_cont {
        width: calc(100% - 390px);
      }
      .right_cont {
        width: 390px;
        padding-left: 150px;
        box-sizing: border-box;

        .title {
          font-size: 14px;
          font-weight: 600;
          color: #333333;
          padding: 20px 0px 10px;
        }

        .rec_course {
          width: 100%;
          height: 1055px;
          overflow-y: scroll;

          .course_block {
            padding-top: 20px;
            cursor: pointer;

            .course_block_img {
              width: 100%;
              height: 146px;
              border-radius: 5px;
              overflow: hidden;

              ::v-deep .el-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .course_block_text {
              width: calc(100% - 20px);
              text-align: center;
              padding: 10px 10px 0px;
              font-size: 14px;
              line-height: 18px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #666666;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .course_block:first-child {
            padding-top: 10px;
          }

          .course_block:last-child {
            padding-bottom: 20px;
          }
        }

        ::v-deep .rec_course::-webkit-scrollbar {
          /*滚动条的宽度*/
          width: 0px;
        }
      }

      .title_con {
        // margin-top: 25px;

        .title {
          // display: flex;
          // justify-content: space-between;
          // align-items: flex-start;

          .title-left {
            width: 100%;
            .elimage {
              width: 100%;
              height: 100%;
              border-radius: 5px;
            }
            .elimage1 {
              width: 100%;
              height: 100%;
              border-radius: 5px;
              background: aliceblue;
              ::v-deep .el-image__inner {
                position: relative;
                width: 65%;
                left: 15%;
              }
            }
          }

          .title-right {
            width: 100%;
            min-height: 155px;
            margin-left: 10px;
            margin-top: 25px;

            .intro-title {
              align-items: center;

              .item {
                // display: flex;
                // justify-content: flex-start;

                .elimage {
                  width: 23px;
                  // height: 23px;
                }

                .item-text {
                  margin-top: 5px;
                }
              }

              .item1 {
                width: 100%;
                margin-top: 16px;
                // margin-right: 35px;
                // display: flex;
                // justify-content: flex-start;
                // align-items: center;

                .elimage {
                  width: 23px;
                  // height: 23px;
                }

                .item-name {
                  // margin-left: 10px;
                  // margin-right: 10px;
                  font-size: 14px;
                  font-weight: 500;
                  color: #999999;
                  // white-space: nowrap;
                }

                .item-text {
                  font-size: 14px;
                  font-weight: 500;
                  // color: #333333;
                  // white-space: nowrap;
                  margin-top: 5px;
                }
              }
            }

            // .title-footer {
            //   margin-top: 20px;
            //   justify-content: center !important;
            //   margin-left: -20px;

            //   .item {
            //     width: 30%;
            //   }
            // }
            .bottom-title {
              margin-left: 0px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 20px;

              .item {
                width: 30%;
                text-align: center;
              }

              .text {
                color: #333333;
                font-size: 14px;
                font-weight: 400;
                line-height: 25px;
                word-break: break-word;
                /* 英文-字母 */
                word-wrap: break-word;
                /* 英文-单词 */
                white-space: pre-line;
                /* 中文 */
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                line-clamp: 4;
                -webkit-box-orient: vertical;
              }

              .score {
                margin-left: 10px;
                margin-right: 10px;
                font-size: 14px;
                font-weight: 400;
                color: #666666;
              }

              .num {
                font-size: 30px;
                font-weight: 500;
                color: #3d84ff;
              }

              .numname {
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #666666;
              }
            }

            .title-right-head {
              margin-top: 30px;
              // display: flex;
              // justify-content: flex-end;
              // align-items: center;
              float: right;
              margin-right: 5%;
            }
          }
        }
      }

      .bottom {
        margin-top: 90px;

        .bottom_con {
          width: 100%;

          .tabbt-contain {
            .bt {
              padding-left: 10px;
              padding-right: 10px;

              .bt-name {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .main-contain_con {
    // margin-top: 100px;

    .contain_up {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 298px;

      .title_con {
        padding: 40px;

        .title_text_border {
          min-width: 400px;

          .title_text1 {
            font-size: 30px;
            color: #ffffff;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            margin-top: 40px;
          }

          .title_text {
            font-size: 30px;
            color: #ffffff;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            margin-top: 30px;
          }

          .time_text {
            color: #ffffff;
            font-size: 16px;
            font-weight: bold;
            text-align: center;
          }
        }
      }
    }

    .contain_down {
      width: 1640px;
      margin: 0px auto;
      display: flex;
      justify-content: center;
      .left_cont {
        width: calc(100% - 390px);
      }
      .right_cont {
        width: 390px;
        padding-left: 150px;
        box-sizing: border-box;

        .title {
          font-size: 14px;
          font-weight: 600;
          color: #333333;
          padding: 20px 0px 10px;
        }

        .rec_course {
          width: 100%;
          height: 1055px;
          overflow-y: scroll;

          .course_block {
            padding-top: 20px;
            cursor: pointer;

            .course_block_img {
              width: 100%;
              height: 146px;
              border-radius: 5px;
              overflow: hidden;

              ::v-deep .el-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .course_block_text {
              width: calc(100% - 20px);
              text-align: center;
              padding: 10px 10px 0px;
              font-size: 14px;
              line-height: 18px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #666666;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .course_block:first-child {
            padding-top: 10px;
          }

          .course_block:last-child {
            padding-bottom: 20px;
          }
        }

        ::v-deep .rec_course::-webkit-scrollbar {
          /*滚动条的宽度*/
          width: 0px;
        }
      }

      .title_con {
        margin-top: 25px;

        .title {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          .title-left {
            width: 42%;
            .elimage {
              width: 100%;
              height: 100%;
              border-radius: 5px;
              background: aliceblue;
              text-align: center;
              ::v-deep .el-image__inner {
                position: relative;
                width: 100%;
                // left: 15%;
              }
            }

            .elimage1 {
              width: 100%;
              height: 100%;
              border-radius: 5px;
              background: aliceblue;
              text-align: center;
              ::v-deep .el-image__inner {
                position: relative;
                width: 65%;
                // left: 15%;
              }
            }
          }

          .title-right {
            width: 58%;
            // min-height: 275px;
            margin-left: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .intro-title {
              // align-items: center;

              .item {
                display: flex;
                justify-content: flex-start;

                .item-info {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                }

                .item-text {
                }
              }

              .map-wrap {
                display: inline-block;

                ::v-deep .map_seo {
                  display: none;
                }

                .map-cont {
                  margin-top: 16px;
                  width: 500px;
                  height: 250px;
                  position: relative;

                  .map-display {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    // height: 0px;
                    overflow: hidden;
                  }

                  // .is_map_show {
                  //   animation: showMap 0.8s forwards;
                  // }
                  // .is_map_hide {
                  //   animation: hideMap 0.8s forwards;
                  // }
                }
              }
            }

            // .title-footer {
            //   margin-top: 50px;

            //   .item {
            //     width: calc(100% / 4);
            //   }
            // }
            .bottom-title {
              margin-left: 0px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 20px;

              .item {
                width: 30%;
                text-align: center;
              }

              .text {
                color: #333333;
                font-size: 14px;
                font-weight: 400;
                line-height: 25px;
                word-break: break-word;
                /* 英文-字母 */
                word-wrap: break-word;
                /* 英文-单词 */
                white-space: pre-line;
                /* 中文 */
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                line-clamp: 4;
                -webkit-box-orient: vertical;
              }

              .score {
                margin-left: 10px;
                margin-right: 10px;
                font-size: 14px;
                font-weight: 400;
                color: #666666;
              }

              .num {
                font-size: 30px;
                font-weight: 500;
                color: #3d84ff;
              }

              .numname {
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #666666;
              }
            }

            .title-right-head {
              margin-top: 50px;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              // width: 35%;
              // display: flex;
              // justify-content: flex-end;
            }
          }
        }
      }

      .bottom {
        margin-top: 40px;

        .bottom_con {
          width: 1200px;

          .tabbt-contain {
            .bt {
              padding-left: 20px;
              padding-right: 20px;

              .bt-name {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.semester {
  display: flex;
  justify-content: flex-start !important;
  align-items: flex-end;
  margin-top: 20px;
  margin-bottom: 10px;

  .title {
    display: block !important;
    // color: #ffffff;
    font-size: 16px;
    // font-weight: bold;
    width: 120px !important;
    height: 40px;
    line-height: 40px;
    margin-right: 10px;

    .num {
      font-size: 30px;
      font-weight: 700;
    }
  }
}

/* 头部下拉选项 */
::v-deep .select {
  .el-input__inner {
    font-size: 15px;
    // font-weight: 700;
    border-radius: 20px;
  }
}

.select_opt {
  .opt_name {
    color: #333;
    font-size: 14px;
    line-height: 30px;
    float: left;
  }

  .opt_status {
    color: #2a7cec;
    font-size: 14px;
    line-height: 30px;
    padding: 0 10px;
    background-color: #dfdfdf;
    border-radius: 20px;
    float: right;
    margin-left: 20px;
  }
}

#myVideo {
  background: #000;
  width: 100%;
  object-fit: contain;
}

// @keyframes showMap {
//   0% {
//     height: 0px;
//   }
//   100% {
//     height: 260px;
//   }
// }
// @keyframes hideMap {
//   0% {
//     height: 260px;
//   }
//   100% {
//     height: 0px;
//   }
// }
@media only screen and (max-width: 640px) {
  .el-message-box {
    width: 95%;
  }
}
</style>
